import {
  useAppcues,
  useCallback,
  useFlagWithUseQueries,
  useQueryFutures,
  useRef,
  useSelector,
  useState,
} from 'src/hooks'

import {
  EditorElement,
  EditorFullBleed,
  EditorImage,
  EditorPanel,
  factoryFullBleeds,
  Position,
} from 'src/editor/EditorCard'

import { TextEditorState, TextSelection } from 'src/editor/text/types'

import { cardUpdate, createCleanBackPanel } from 'src/editor/CardUpdate'

import { getDesignColorsBySendableCardID } from 'src/legacy_graphql'
import { Props, Step, Steps } from './types'
import * as Utils from './utils'
import removeUsersnap from 'src/helpers/removeUsersnap'
import { IconType } from '@sendoutcards/quantum-design-ui'
import { selectedVariationId } from 'src/redux/selectors/editor'
import {
  useCreateOrUpdateBackPanel,
  useFonts,
  useGetCard,
  useUpdateCard,
} from 'src/react_query'
import {
  CardFragment,
  CardType,
  ColorFragment,
  ImageFragment,
} from 'src/graphql/generated/graphql'

const idle = (fullBleedIndex: number): Step => ({
  type: Steps.Idle,
  fullBleedIndex,
  panelIndex: 0,
  elementIndex: undefined,
  textSelection: undefined,
})

type OptionalPanel<T extends Step> = T['elementIndex'] extends number
  ? EditorPanel
  : undefined | EditorPanel

type OptionalElement<T extends Step> = T['elementIndex'] extends number
  ? T['panelIndex'] extends number
    ? EditorElement
    : undefined | EditorElement
  : undefined | EditorElement

type LastLayoutPath = {
  fullBleedIndex: number
  panelIndex: number | undefined
  layoutId: string | undefined
}

type ErrorState = {
  title: string
  error: unknown
}

export type ToasterNotification = {
  iconName: IconType
  content: string
  type: 'success' | 'danger'
}

export type DesignColors = {
  isLoading: boolean
  palette?: ColorFragment[]
}

const useApi = (props: Props) => {
  const { cardId, onAfterSave, onClose } = props

  const [shouldLoadDesignColors, setShouldLoadDesignColors] = useState(false)
  const { data: fonts } = useFonts()
  const {
    bulkOrderFlow: canShowBulkOrderOptions,
    newCardEditor: canShowNewCardEditor,
  } = useFlagWithUseQueries()
  const { data: cardQuery } = useGetCard({ id: cardId }, { suspense: true })

  const updateCardMutation = useUpdateCard()
  const createOrUpdateBackPanelMutation = useCreateOrUpdateBackPanel()
  const card = cardQuery?.card
  const [fullBleeds, setFullBleeds] = useState(
    card ? factoryFullBleeds(card) : [],
  )

  const sendableCardId = card
    ? selectedVariationId(card, fullBleeds) ?? card.detailedSendableCard?.id
    : undefined

  const designColorsQuery = sendableCardId
    ? getDesignColorsBySendableCardID({ id: sendableCardId, quantity: 13 })
    : undefined

  const [designColorsResponse] = useQueryFutures(designColorsQuery)

  const designColors: DesignColors = designColorsResponse
    ? {
        palette:
          !designColorsResponse.isUnresolved &&
          !designColorsResponse.error &&
          designColorsResponse.value
            ? (designColorsResponse.value as ColorFragment[])
            : undefined,
        isLoading: designColorsResponse.isUnresolved,
      }
    : {
        palette: undefined,
        isLoading: false,
      }

  const { isMobile } = useSelector(state => state.window)
  const [isCreatingBackPanel, setIsCreatingBackPanel] = useState(false)
  const [view, setView] = useState<'fullbleed' | 'panel'>(
    isMobile ? 'panel' : 'fullbleed',
  )
  const [panelView, setPanelView] = useState<'fullbleed' | 'panel'>(
    isMobile ? 'panel' : 'fullbleed',
  )
  const [isSaving, setIsSaving] = useState(false)
  const [step, setStep] = useState<Step>(idle(0))
  const [selectedLayoutId, setSelectedLayoutId] = useState<string>()
  const [selectedBackLayoutId, setSelectedBackLayoutId] = useState<string>()
  const { fullBleedIndex, panelIndex } = step
  const [currentLayout, setCurrentLayout] = useState<
    LastLayoutPath | undefined
  >(undefined)
  const [isApiHandlingTextChanges, setIsApiHandlingTextChanges] = useState(
    false,
  )
  const [editorDuvetOpenValue, setEditorDuvetOpenValue] = useState(0)
  const [isImageSettingExpanded, setIsImageSettingExpanded] = useState(false)
  const [isImageScaleActive, setIsImageScaleActive] = useState(false)
  const [errorState, setErrorState] = useState<ErrorState | undefined>()

  // Mobile text element
  const mobileTextAreaRef = useRef<HTMLTextAreaElement>(null)
  const [mobileTextElementSigId, setMobileTextElementSigid] = useState<string>()

  const [
    toasterNotification,
    setToasterNotification,
  ] = useState<ToasterNotification | null>(null)

  const backFullBleedPanel = card ? fullBleeds[fullBleeds.length - 1] : null

  const selectedFullBleed = fullBleeds[step.fullBleedIndex]

  const isHorizontal = !!card?.isHorizontal

  const resetStep = () => setStep(idle(step.fullBleedIndex))

  const setIdleStep = (fullBleedIndex: number, panelIndex: number) =>
    setStep({
      type: Steps.Idle,
      fullBleedIndex,
      panelIndex,
      elementIndex: undefined,
      textSelection: undefined,
    })

  const updateCard = async (
    card: CardFragment,
    fullBleeds: EditorFullBleed[],
  ) => {
    const { updateCard } = await updateCardMutation.mutateAsync({
      card: cardUpdate(card, fullBleeds),
    })
    return updateCard.card
  }

  const resolveLocation = (selectedCardType: CardType) => {
    if (fullBleedIndex === 0) {
      return 'Front'
    }
    if (fullBleedIndex) {
      switch (selectedCardType) {
        case CardType.Postcard:
        case CardType.Flatcard:
          if (fullBleedIndex === 1) {
            return 'Back'
          }
          break
        case CardType.TwoPanel:
        case CardType.TwoPanelBig:
          if (fullBleedIndex === 1 && panelView === 'fullbleed') {
            return 'Inside'
          } else if (
            isHorizontal &&
            fullBleedIndex === 1 &&
            panelView === 'panel'
          ) {
            return panelIndex === 0
              ? 'Inside Top'
              : panelIndex === 1
              ? 'Inside Bottom'
              : 'Inside'
          } else if (
            !isHorizontal &&
            fullBleedIndex === 1 &&
            panelView === 'panel'
          ) {
            return panelIndex === 0
              ? 'Inside Left'
              : panelIndex === 1
              ? 'Inside Right'
              : 'Inside'
          } else if (fullBleedIndex === 2) {
            return 'Back'
          }
          break
        case CardType.ThreePanel:
          if (fullBleedIndex === 1 && panelView === 'fullbleed') {
            return 'Inside'
          } else if (
            isHorizontal &&
            fullBleedIndex === 1 &&
            panelView === 'panel'
          ) {
            return panelIndex === 0
              ? 'Inside Top'
              : panelIndex === 1
              ? 'Inside Middle'
              : panelIndex === 2
              ? 'Inside Bottom'
              : 'Inside'
          } else if (
            !isHorizontal &&
            fullBleedIndex === 1 &&
            panelView === 'panel'
          ) {
            return panelIndex === 0
              ? 'Inside Left'
              : panelIndex === 1
              ? 'Inside Middle'
              : panelIndex === 2
              ? 'Inside Right'
              : 'Inside'
          } else if (fullBleedIndex === 2) {
            return 'Outside Flap'
          } else if (fullBleedIndex === 3) {
            return 'Back'
          }
          break
        default:
          return null
      }
    }
    return
  }

  const resolveNavigation = (
    currentLocation: string | undefined | null,
    selectedCardType: CardType,
    isPrev: boolean,
  ) => {
    switch (selectedCardType) {
      case CardType.Postcard:
      case CardType.Flatcard:
        if (currentLocation === 'Front') {
          return isPrev ? null : 'Back'
        }
        if (currentLocation === 'Back') {
          return isPrev ? 'Front' : null
        }
        break
      case CardType.TwoPanel:
      case CardType.TwoPanelBig:
        if (panelView === 'fullbleed') {
          if (currentLocation === 'Front') {
            return isPrev ? null : 'Inside'
          } else if (currentLocation === 'Inside') {
            return isPrev ? 'Front' : 'Back'
          } else if (currentLocation === 'Back') {
            return isPrev ? 'Inside' : null
          }
        } else if (panelView === 'panel') {
          if (currentLocation === 'Front') {
            return isPrev ? null : 'Inside Left'
          } else if (currentLocation === 'Inside Left') {
            return isPrev ? 'Front' : 'Inside Right'
          } else if (currentLocation === 'Inside Right') {
            return isPrev ? 'Inside Left' : 'Back'
          } else if (currentLocation === 'Back') {
            return isPrev ? 'Inside Right' : null
          }
        }
        break
      case CardType.ThreePanel:
        if (panelView === 'fullbleed') {
          if (currentLocation === 'Front') {
            return isPrev ? null : 'Inside'
          } else if (currentLocation === 'Inside') {
            return isPrev ? 'Front' : 'Outside Flap'
          } else if (currentLocation === 'Outside Flap') {
            return isPrev ? 'Inside' : 'Back'
          } else if (currentLocation === 'Back') {
            return isPrev ? 'Outside Flap' : null
          }
        } else if (panelView === 'panel') {
          if (currentLocation === 'Front') {
            return isPrev ? null : 'Inside Left'
          } else if (currentLocation === 'Inside Left') {
            return isPrev ? 'Front' : 'Inside Middle'
          } else if (currentLocation === 'Inside Middle') {
            return isPrev ? 'Inside Left' : 'Inside Right'
          } else if (currentLocation === 'Inside Right') {
            return isPrev ? 'Inside Middle' : 'Outside Flap'
          } else if (currentLocation === 'Outside Flap') {
            return isPrev ? 'Inside Right' : 'Back'
          } else if (currentLocation === 'Back') {
            return isPrev ? 'Outside Flap' : null
          }
        }
        break
      default:
        return null
    }
    return
  }

  const getTourId = () => {
    if (fullBleedIndex === 0) {
      return '-L9v2SyS08C2D8zfOr9i'
    } else if (step.type === Steps.EditLayout) {
      return '-L8s7aWp_fe9k3CzqMzg'
    } else if (step.type === Steps.EditText) {
      return '-L9qbIuOIV8A8AP_KJAw'
    } else if (step.type === Steps.Idle) {
      return '-LBvgup5KcAEbdohEbSZ'
    } else if (step.type === Steps.EditPicture) {
      return '-L9uqmNyXhnpOmnHswTV'
    } else {
      return undefined
    }
  }

  const handleSave = async () => {
    setIsSaving(true)
    try {
      if (card) await updateCard(card, fullBleeds)
    } catch (error) {
      setErrorState({
        title: 'There was an error saving your card',
        error: error,
      })
      console.error(
        'Failed to save the card' + (error ? ': ' + error.toString() : ''),
      )
      throw error
    } finally {
      setIsSaving(false)
    }
  }

  const handleSaveAndSend = async () => {
    setIsSaving(true)
    try {
      if (card) await updateCard(card, fullBleeds)
      if (selectedBackLayoutId) {
        await updateLayout(selectedBackLayoutId)
      }
      await onAfterSave()
    } catch (error) {
      setErrorState({
        title: 'There was an error saving your card',
        error: error,
      })
      console.error(
        'Failed to save the card' + (error ? ': ' + error.toString() : ''),
      )
      throw error
    } finally {
      removeUsersnap()
      setIsSaving(false)
    }
  }

  const updateFullBleed = (
    fullBleedIndex: number,
    update: (fullBleed: EditorFullBleed) => EditorFullBleed,
  ) => {
    setFullBleeds(Utils.updateFullBleed(fullBleeds, fullBleedIndex, update))
  }

  const updatePanel = useCallback(
    (
      fullBleedIndex: number,
      panelIndex: number,
      update: (panel: EditorPanel) => EditorPanel,
    ) => {
      setFullBleeds(
        Utils.updatePanel(fullBleeds, fullBleedIndex, panelIndex, update),
      )
    },
    [setFullBleeds, fullBleeds],
  )

  const editLayout = (fullBleedIndex: number, panelIndex: number) =>
    setStep({ type: Steps.EditLayout, fullBleedIndex, panelIndex })

  const editPicture = (
    fullBleedIndex: number,
    panelIndex: number,
    elementIndex: number,
    source: 'own' | 'social',
  ) =>
    setStep({
      type: Steps.EditPicture,
      fullBleedIndex,
      panelIndex,
      elementIndex,
      source,
    })

  const editBackgroundColor = (fullBleedIndex: number, panelIndex: number) =>
    setStep({ type: Steps.EditBackgroundColor, fullBleedIndex, panelIndex })

  const editBackgroundPicture = (
    fullBleedIndex: number,
    source: 'own' | 'social',
  ) =>
    setStep({
      type: Steps.EditBackgroundPicture,
      fullBleedIndex,
      source,
    })

  const removePicture = (
    fullBleedIndex: number,
    panelIndex: number | undefined,
    elementIndex: number,
  ) => {
    setFullBleeds(
      Utils.updateElementImage(
        fullBleeds,
        fullBleedIndex,
        panelIndex,
        elementIndex,
        () => ({
          image: null,
          position: { x: 0.5, y: 0.5 },
          scale: 1,
          filter: null,
        }),
      ),
    )
  }

  const updateElement = (
    fullBleedIndex: number,
    panelIndex: number,
    elementIndex: number,
    update: (element: EditorElement, panel?: EditorPanel) => EditorElement,
  ) => {
    setFullBleeds(
      Utils.updateElement(
        fullBleeds,
        fullBleedIndex,
        panelIndex,
        elementIndex,
        update,
      ),
    )
  }

  const removeBackgroundPicture = (fullBleedIndex: number) =>
    updateFullBleed(fullBleedIndex, fullBleed => ({
      ...fullBleed,
      backgroundElement: fullBleed.backgroundElement && {
        ...fullBleed.backgroundElement,
        image: {
          image: null,
          position: { x: 0.5, y: 0.5 },
          scale: 1,
          filter: null,
        },
      },
    }))

  const setElementImage = (
    fullBleedIndex: number,
    panelIndex: number | undefined,
    elementIndex: number,
    image: ImageFragment,
  ) => {
    setFullBleeds(
      Utils.updateElementImage(
        fullBleeds,
        fullBleedIndex,
        panelIndex,
        elementIndex,
        () => ({
          image: {
            __typename: 'Image',
            id: image.id,
            url: decodeURI(image.url),
            smallThumb: decodeURI(image.smallThumb || image.url),
            width: image.width,
            height: image.height,
          },
          position: { x: 0.5, y: 0.5 },
          scale: 1,
          filter: null,
        }),
      ),
    )
  }

  const updateElementImage = (
    fullBleedIndex: number,
    panelIndex: number | undefined,
    elementIndex: number,
    update: (image: EditorImage) => EditorImage,
  ) => {
    setFullBleeds(
      Utils.updateElementImage(
        fullBleeds,
        fullBleedIndex,
        panelIndex,
        elementIndex,
        update,
      ),
    )
  }

  const updateLayout = async (selectedLayoutId: string) => {
    if (!backFullBleedPanel) return

    try {
      await createOrUpdateBackPanelMutation.mutateAsync({
        panel: createCleanBackPanel({
          ...backFullBleedPanel,
          backgroundElement: backFullBleedPanel.backgroundElement,
        }),
        layoutId: selectedLayoutId,
      })
    } catch (error) {
      setErrorState({
        title: 'There was an error updating your card layout',
        error: error,
      })
      console.error(
        'Failed to update layout' + (error ? ': ' + error.toString() : ''),
      )
      throw error
    }
  }

  const selectPanel = (fullBleedIndex: number, panelIndex: number) =>
    setStep({
      type: Steps.Idle,
      fullBleedIndex,
      panelIndex,
      elementIndex: undefined,
      textSelection: undefined,
    })

  const selectFullBleed = (fullBleedIndex: number) =>
    setStep({
      type: Steps.Idle,
      fullBleedIndex,
      panelIndex: 0,
      elementIndex: undefined,
      textSelection: undefined,
    })

  const updateText = (
    fullBleedIndex: number,
    panelIndex: number,
    elementIndex: number,
    textSelection: TextSelection,
    update: (text: TextEditorState) => TextEditorState,
    shouldNotSelectText?: boolean,
  ) => {
    setIsApiHandlingTextChanges(true)
    updateElement(fullBleedIndex, panelIndex, elementIndex, element => {
      if (element.text) {
        const nextState = update({
          text: element.text,
          selection: textSelection,
        })

        if (!shouldNotSelectText) {
          selectText(
            fullBleedIndex,
            panelIndex,
            elementIndex,
            nextState.selection,
          )
        }

        return {
          ...element,
          text: nextState.text,
        }
      }

      return element
    })
  }

  const finishedTextChanges = useCallback(() => {
    setIsApiHandlingTextChanges(false)
  }, [setIsApiHandlingTextChanges])

  const selectText = (
    fullBleedIndex: number,
    panelIndex: number,
    elementIndex: number,
    textSelection: TextSelection,
  ) =>
    setStep({
      type: Steps.EditText,
      fullBleedIndex,
      panelIndex,
      elementIndex,
      textSelection,
    })

  const getSelectedElement = <T extends Step>(step: T): OptionalElement<T> => {
    if (
      typeof step.panelIndex === 'number' &&
      typeof step.elementIndex === 'number'
    ) {
      return fullBleeds[step.fullBleedIndex].panels[step.panelIndex].elements[
        step.elementIndex
      ] as OptionalElement<T>
    }
    return undefined as OptionalElement<T>
  }

  const getSelectedPanel = <T extends Step>(step: T): OptionalPanel<T> => {
    if (typeof step.panelIndex === 'number') {
      return fullBleeds[step.fullBleedIndex].panels[
        step.panelIndex
      ] as OptionalPanel<T>
    }
    return undefined as OptionalPanel<T>
  }

  const getCorrectedImagePosition = (
    scale: number,
    position: Position,
  ): Position => {
    // when the scale changes to 1 from anything less than 1
    // it looks like it is fine on the editor, but it
    // is actually positioned wrong, this fix below forces it to center
    // if the user likes this position, it will be fine when
    // flattened, if they want to manually move it, it will still
    // be updated as usual
    return scale >= 0.96 && scale <= 1.04 ? { x: 0.5, y: 0.5 } : position
  }

  useAppcues(getTourId())

  return {
    card,
    fullBleeds,
    step,
    setStep,
    setFullBleeds,
    shouldLoadDesignColors,
    setShouldLoadDesignColors,
    fonts,
    view,
    setView,
    updatePanel,
    selectedLayoutId,
    setSelectedLayoutId,
    selectedBackLayoutId,
    setSelectedBackLayoutId,
    isCreatingBackPanel,
    setIsCreatingBackPanel,
    editLayout,
    editBackgroundColor,
    removePicture,
    removeBackgroundPicture,
    selectedFullBleed,
    updateElementImage,
    updateFullBleed,
    editPicture,
    editBackgroundPicture,
    setElementImage,
    isSaving,
    setIsSaving,
    resolveLocation,
    resolveNavigation,
    setPanelView,
    updateLayout,
    handleSave,
    handleSaveAndSend,
    isMobile,
    panelView,
    onClose,
    selectPanel,
    selectFullBleed,
    selectText,
    updateElement,
    updateText,
    resetStep,
    setIdleStep,
    updateCard,
    getSelectedElement,
    getSelectedPanel,
    getCorrectedImagePosition,
    isApiHandlingTextChanges,
    finishedTextChanges,
    editorDuvetOpenValue,
    setEditorDuvetOpenValue,
    isImageSettingExpanded,
    setIsImageSettingExpanded,
    isImageScaleActive,
    setIsImageScaleActive,
    errorState,
    setErrorState,
    toasterNotification,
    setToasterNotification,
    currentLayout,
    setCurrentLayout,
    designColors,
    canShowBulkOrderOptions,
    canShowNewCardEditor,
    mobileTextAreaRef,
    mobileTextElementSigId,
    setMobileTextElementSigid,
  }
}

export default useApi

export type Api = ReturnType<typeof useApi>
