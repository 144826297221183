import { useCallback, useEffect, useMeasurements, useState } from '.'

const useScreenOrientation = () => {
  const { windowWidth, windowHeight } = useMeasurements()

  const getOrientation = useCallback((): OrientationType => {
    if (window.screen.orientation) {
      return window.screen.orientation.type
    } else {
      return windowWidth > windowHeight
        ? 'landscape-primary'
        : 'portrait-primary'
    }
  }, [windowWidth, windowHeight])

  const [orientation, setOrientation] = useState<OrientationType>(
    getOrientation(),
  )

  const updateOrientation = useCallback(() => {
    setOrientation(getOrientation())
  }, [setOrientation, getOrientation])

  useEffect(() => {
    updateOrientation()
  }, [updateOrientation, windowWidth, windowHeight])

  useEffect(() => {
    const hasScreenOrientationAPI = !!window.screen.orientation
    if (hasScreenOrientationAPI) {
      window.screen.orientation.addEventListener('change', updateOrientation)
    } else {
      window.addEventListener('orientationchange', updateOrientation)
    }
    return () => {
      if (hasScreenOrientationAPI) {
        window.screen.orientation.removeEventListener(
          'change',
          updateOrientation,
        )
      } else {
        window.removeEventListener('orientationchange', updateOrientation)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    orientation,
  }
}

export default useScreenOrientation
